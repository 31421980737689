import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import HomeComponent from "../components/HomeComponent";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../GlobalState";
import Api from "../components/Api";
import { Helmet } from "react-helmet";

export default function Home() {

    const { fixedTitles, global } = useContext(GlobalState);
    const [apiOptions, setApiOptions] = useState({});

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false)

    function contactUs(e) {
        e.preventDefault();
        if (loading) return;

        setLoading(true);
        setApiOptions({
            url: '/contact-us',
            method: 'post',
            data: {
                'first_name': firstName,
                'last_name': lastName,
                'email': email,
                'phone_number': phoneNumber,
                'message': message,
            },
            then: (r) => {
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
                setErrorMessages('');
                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            },
            catch422: (errors) => {
                setSuccessMessage('');
                setErrorMessages(errors);
            },
            finally: () => {
                setLoading(false);
            }
        });

    }

    function scrollTop() {
        const element = document.getElementById("home-section-1")
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }


    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (

        <Layout activePage="home">
            <Helmet>
                <title>{global?.seo_pages['home'].title}</title>
                <meta name="title" content={global?.seo_pages['home'].title} />
                <meta name="description" content={global?.seo_pages['home'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['home'].title} />
                <meta property="og:description" content={global?.seo_pages['home'].description} />
                <meta property="og:image" content={global?.seo_pages['home'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['home'].title} />
                <meta property="twitter:description" content={global?.seo_pages['home'].description} />
                <meta property="twitter:image" content={global?.seo_pages['home'].image} />
            </Helmet>
            {global ?
                <>

                    <div className="position-relative ">
                        <div className="container-fluid">
                            <div className="row text-header ms-lg-3 ms-md-2" animate="left">
                                <div className="col-lg-12">
                                    <div className="mb-0 mt-5 " dangerouslySetInnerHTML={{ __html: global.homepage.title }} />
                                </div>
                            </div>
                        </div>

                        <div className="ratio background-image">
                            <video playsInline muted loop autoPlay>
                                <source src={global.homepage.banner} type='video/mp4' />
                            </video>
                        </div>


                        <div className="opacity-header"></div>
                        <div className="section-1">
                            <div className="container-fluid" >
                                <div className="text-end mx-lg-3">

                                    <img onClick={scrollTop} className="arrow-down" src="/assets/images/header-arrow.svg" alt="video" />

                                </div>
                            </div>
                        </div>
                        {/* <p className="position-relative" onClick={toggleMute}>Toggle Mute</p> */}
                    </div>
                    {fixedTitles ?
                        <>
                            <div className="position-relative py-3">
                                <div className="about-section-bg">
                                    <img src="/assets/images/background-section1.png" alt="background" />
                                </div>
                                <div className="container-fluid about-section py-5" id="home-section-1">
                                    <div className="row  justify-content-center">
                                        <div className="col-lg-9 col-md-9 col-sm-9  text-center" animate="left">
                                            <h2 className="mb-5" >{fixedTitles?.about_us}</h2>
                                            <div className="mb-5" dangerouslySetInnerHTML={{ __html: global.homepage.about_text }} />

                                            <Link to="/about">
                                                <div className="button d-flex align-items-center justify-content-center text-center">
                                                    <h3 className="mb-0 me-3">{fixedTitles?.about_button}</h3>
                                                    {
                                                        localStorage.getItem("language") === 'en' ?
                                                            <img src="/assets/images/button-arrow.svg" alt="button" />
                                                            :
                                                            <img src="/assets/images/button-ar.svg" alt="button" />
                                                    }
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="position-relative">
                                <div className="bg-services">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100.37" height="103.729" viewBox="0 0 192.37 103.729">
                                        <path id="Path_3" data-name="Path 3" d="M1727.629,3351.714l3.848,7.71,188.522-94.1v-9.632Z" transform="translate(-1727.629 -3255.695)" fill="#80808061" />
                                    </svg>
                                </div>
                                <div className="container-fluid pt-5" animate="right">
                                    <div className="text-center">
                                        <h2 className="mb-5">{fixedTitles.services}</h2>
                                    </div>

                                    <div className="row mb-5  mx-lg-3 mx-md-2">
                                        {
                                            global.home_services.map((service, index) =>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 pb-lg-0 pb-4" key={index}>
                                                    <Link to={"/services/" + service.slug}>
                                                        <div className="services">
                                                            <HomeComponent
                                                                title={service.banner_title}
                                                                image={service.banner_image}
                                                            />
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <Link to="/services">
                                        <div className="button d-flex align-items-center justify-content-center text-center">
                                            <h3 className="mb-0 me-3">{fixedTitles.our_services_button}</h3>
                                            {
                                                localStorage.getItem("language") === 'en' ?
                                                    <img src="/assets/images/button-arrow.svg" alt="button" />
                                                    :
                                                    <img src="/assets/images/button-ar.svg" alt="button" />
                                            }
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="ratio section-between">
                                <img src="/assets/images/line.png" alt="" />
                            </div>

                            <div className="container-fluid pb-5" animate="left">
                                <div className="text-center">
                                    <h2 className="mb-5">{fixedTitles?.insights}</h2>
                                </div>
                                <div className="row mb-5  mx-lg-3 mx-md-2">
                                    {
                                        global.home_insights.map((insight, index) =>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-12 pb-lg-0 pb-4" key={index}>
                                                {
                                                    insight?.pdf_file ?
                                                        <a href={insight?.pdf_file} target="_blank" rel="noreferrer" download>
                                                            <div className="services">
                                                                <HomeComponent
                                                                    title={insight.title}
                                                                    image={insight.image_outside}
                                                                />
                                                            </div>
                                                        </a>
                                                        :
                                                        <Link to={"/insights/" + insight.slug}>
                                                            <div className="services">
                                                                <HomeComponent
                                                                    title={insight.title}
                                                                    image={insight.image_outside}
                                                                />
                                                            </div>
                                                        </Link>
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                                <Link to="/insights">
                                    <div className="button d-flex align-items-center justify-content-center text-center">
                                        <h3 className="mb-0 me-3">{fixedTitles?.insights_button}</h3>
                                        {localStorage.getItem("language") === 'en' ?
                                            <img src="/assets/images/button-arrow.svg" alt="button" />
                                            :
                                            <img src="/assets/images/button-ar.svg" alt="button" />
                                        }
                                    </div>
                                </Link>
                            </div>

                            <div className="position-relative py-5">
                                <div className="team-section-bg d-md-block d-none">
                                    <img src="/assets/images/background-section2.png" alt="" />
                                </div>
                                <div className="team-section-bg-mobile d-md-none d-sm-block d-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2048.288" height="638.197" viewBox="0 0 2048.288 638.197">
                                        <g id="Group_282" data-name="Group 282" transform="translate(-5864.228 -3541.24)">
                                            <rect className="stroke-mobile" id="Rect angle_239" data-name="Rectangle 239" width="1983.942" height="5" transform="translate(5868.61 3727.205) rotate(-5.363)" fill="#978175" stroke="#978175" strokeWidth="1" opacity="0.75" />
                                            <rect className="stroke-mobile" id="Rectangle_240" data-name="Rectangle 240" width="2055.39" height="5" transform="translate(7911.166 4178.892) rotate(-174.637)" fill="#978175" stroke="#978175" strokeWidth="1" opacity="0.75" />
                                        </g>
                                    </svg>
                                </div>

                                <div className="container-fluid " animate="right">
                                    <div className="row justify-content-center  mx-lg-3 mx-md-2 py-lg-3">
                                        <div className="col-lg-9 text-center">
                                            <h2 className="mb-sm-5 mb-3">{fixedTitles?.join_the_team}</h2>
                                            <div className="mb-sm-5 mb-4" dangerouslySetInnerHTML={{ __html: global.homepage.team_text }} />
                                            <Link to="/join-the-team">
                                                <div className="button d-flex align-items-center justify-content-center text-center">
                                                    <h3 className="mb-0 me-3">{fixedTitles?.join_the_team_button}</h3>
                                                    {
                                                        localStorage.getItem("language") === 'en' ?
                                                            <img src="/assets/images/button-arrow.svg" alt="button" />
                                                            :
                                                            <img src="/assets/images/button-ar.svg" alt="button" />
                                                    }
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid pt-5 contact-form">
                                <h2 className="text-center mb-5" animate="up">{fixedTitles?.contact}</h2>
                                <Api options={apiOptions} />
                                <form className="row  mx-lg-3 mx-md-2" onSubmit={contactUs}>
                                    <div className="col-lg-6" animate="left">
                                        <div className="text-center text-md-start">
                                            <div dangerouslySetInnerHTML={{ __html: global.homepage.contact_text }} />
                                        </div>
                                        <div className="row pt-5">
                                            <div className="col-lg-5 col-6 ">
                                                <div className="pb-5">
                                                    <h5 className="mb-3">{fixedTitles?.first_name}</h5>
                                                    {
                                                        errorMessages.first_name ?
                                                            <input className="input-form red-border" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                            :
                                                            <input className="input-form" type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                                    }
                                                    <div className="add-height">
                                                        {
                                                            errorMessages.first_name ?

                                                                <p className=" error-message mb-0">{errorMessages.first_name}</p>

                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="pb-5">
                                                    <h5 className="mb-3">{fixedTitles?.email_label}</h5>
                                                    {
                                                        errorMessages.email ?
                                                            <input className="input-form red-border" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                            :
                                                            <input className="input-form" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                                    }
                                                    <div className="add-height">
                                                        {
                                                            errorMessages.email ?
                                                                <p className=" error-message mb-0">{errorMessages.email}</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-6 h-100">
                                                <div className="pb-5">
                                                    <h5 className="mb-3">{fixedTitles?.last_name_label}</h5>
                                                    {
                                                        errorMessages.last_name ?
                                                            <input className="input-form red-border" type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                            :
                                                            <input className="input-form" type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                                                    }
                                                    <div className="add-height">
                                                        {
                                                            errorMessages.last_name ?
                                                                <p className=" error-message mb-0">{errorMessages.last_name}</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="pb-5">
                                                    <h5 className="mb-3">{fixedTitles?.phone_number_label}</h5>
                                                    {
                                                        errorMessages.phone_number ?
                                                            <input className="input-form red-border" type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                            :
                                                            <input className="input-form" type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                                    }
                                                    <div className="add-height">
                                                        {
                                                            errorMessages.phone_number ?
                                                                <p className=" error-message mb-0">{errorMessages.phone_number}</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10 col-12 pb-5">
                                                <h5 className="mb-3">{fixedTitles?.message}</h5>
                                                {errorMessages.message ?
                                                    <input className="input-form red-border" type="text" value={message} onChange={e => setMessage(e.target.value)} />
                                                    :
                                                    <input className="input-form" type="text" value={message} onChange={e => setMessage(e.target.value)} />
                                                }
                                                {
                                                    errorMessages.message ?
                                                        <p className=" error-message mb-0">{errorMessages.message}</p>
                                                        :
                                                        null
                                                }


                                                <button disabled={loading} className="button d-flex align-items-center pt-5" type="submit">
                                                    {
                                                        loading ?
                                                            <h3 className="mb-0 me-3">Loading</h3>
                                                            :
                                                            <h3 className="mb-0 me-3">{fixedTitles?.contact_button}</h3>
                                                    }

                                                    {
                                                        localStorage.getItem("language") === 'en' ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.883" height="20.228" viewBox="0 0 24.883 20.228">
                                                                <g id="Group_103" data-name="Group 103" transform="translate(-6091.364 -4956.472)">
                                                                    <line id="Line_25" data-name="Line 25" x2="22.48" transform="translate(6092.364 4966.576)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                    <line id="Line_26" data-name="Line 26" x1="6.19" y1="8.72" transform="translate(6108.663 4957.866)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                    <line id="Line_27" data-name="Line 27" y1="8.72" x2="6.19" transform="translate(6108.663 4966.585)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                </g>
                                                            </svg>

                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24.883" height="20.228" viewBox="0 0 24.883 20.228">
                                                                <g id="Group_277" data-name="Group 277" transform="translate(1.394 1.394)">
                                                                    <g id="Group_276" data-name="Group 276">
                                                                        <line id="Line_80" data-name="Line 80" x1="22.48" transform="translate(0.009 8.729)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                        <line id="Line_81" data-name="Line 81" x2="6.19" y2="8.72" transform="translate(0 8.719)" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                        <line id="Line_82" data-name="Line 82" x1="6.19" y2="8.72" fill="none" stroke="#4d4d4d" strokeLinecap="round" strokeMiterlimitt="10" strokeWidth="2" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                    }
                                                </button>
                                                {
                                                    successMessage ?
                                                        <div className="add-height">
                                                            <p className="success">{fixedTitles?.success_message}</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 pt-lg-0 pb-5 map" animate="right">
                                        <div className="position-relative h-100 px-0">
                                            <iframe title="map" className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115457.82546987632!2d51.43751534104942!3d25.26866637447204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c54770e407c5%3A0xef5881aaa6b0aa6e!2sStrategy%20Hub!5e0!3m2!1sen!2slb!4v1647856775917!5m2!1sen!2slb" style={{ "width": "100%", "height": "350px", "border": "0" }} loading="lazy" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                        :
                        null
                    }

                </>
                :
                null
            }
        </Layout >
    )
}