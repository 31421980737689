export default function SingleServicePage(props) {
    return (
        <div className="container py-5">
            <div className="row justify-content-between" animate="left">
                <div className="mb-5">
                    <h3 className="mb-0 justify-text">{props.title}</h3>
                    <hr className="m-0" />
                </div>
                <h3 className="justify-text">{props.subtitle}</h3>
                <div className="col-lg-6 pt-5" animate="left">
                    <h3 className="mb-0 justify-text">{props.paragraphOne}</h3>
                </div>
                <div className="col-lg-6 pt-5" animate="right">
                    <div className="ratio single-service-1">
                        <img src={props.image} alt="service" />
                    </div>
                </div>
            </div>
        </div>
    )
}