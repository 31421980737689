import { Link } from "react-router-dom";

export default function Publication({publication, fixedTitles}) {
  return (
    <Link to={"/publications/" + publication?.slug}>
      <div className="publication-wrapper position-relative d-flex align-items-end">
        <div className="publication-overlay"></div>
        <img
          className="publication-img"
          src={publication?.image_path}
          alt="publication"
        />

        <div
          className="position-relative publication-info"
          style={{ zIndex: "2", padding: "16px" }}
        >
          <p className="publication-date mb-0">{publication?.formatted_date}</p>
          <p className="publication-title">{publication?.title}</p>
          <div className="d-flex align-items-center">
            <p className="read-more-publication">{fixedTitles.read_more}</p>
            <img
              src="/assets/images/pub-arrow.svg"
              alt="arrow"
              className="ms-3"
              style={{
                transform:
                  "rotate(" +
                  (localStorage.getItem("language") === "en" ? "0" : "180deg") +
                  ")",
              }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
