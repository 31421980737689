import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import GlobalState from "../GlobalState";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, Route } from "react-router-dom";
import Publication from "../components/Publication";
import { Helmet } from "react-helmet";

async function fetchPublications(page = 1) {
  const { data } = await axios.get("/publications?page=" + page);
  return data;
}

export default function Publications() {
  const { fixedTitles, global } = useContext(GlobalState);

  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);

  const { data, error, isFetching, isPreviousData } = useQuery({
    queryKey: ["publications", page],
    queryFn: async () => {
      const data = await fetchPublications(page);
      return data;
    },
    keepPreviousData: true,
    staleTime: 5000,
  });

  console.log(data);
  console.log(localStorage.getItem("language"));

  const handlePageChange = (newPage) => {
    if (newPage === page) return;

    setPage(newPage);
  };

  const getNextPage = () => {
    if (page === data?.page_items?.last_page) return;

    setPage(page + 1);
  };

  const getPrevPage = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  return data?.page_items?.data?.length && fixedTitles ? (
    <Layout activePage="publications">
      <Helmet>
        <title>{global?.seo_pages["publications"].title}</title>
        <meta name="title" content={global?.seo_pages["publications"].title} />
        <meta
          name="description"
          content={global?.seo_pages["publications"].description}
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location} />
        <meta
          property="og:title"
          content={global?.seo_pages["publications"].title}
        />
        <meta
          property="og:description"
          content={global?.seo_pages["publications"].description}
        />
        <meta
          property="og:image"
          content={global?.seo_pages["publications"].image}
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location} />
        <meta
          property="twitter:title"
          content={global?.seo_pages["publications"].title}
        />
        <meta
          property="twitter:description"
          content={global?.seo_pages["publications"].description}
        />
        <meta
          property="twitter:image"
          content={global?.seo_pages["publications"].image}
        />
      </Helmet>
      <div className="container-fluid py-5">
        <div className="text-center py-lg-5 my-5">
          <h2 className="mb-0 pt-5">{fixedTitles.publications}</h2>
        </div>
        <div className="row mx-lg-3 mx-md-2">
          {data?.page_items?.data?.map((publication) => (
            <div key={publication?.id} className="col-xl-3 col-lg-4 col-sm-6 mb-3">
              <Publication
                fixedTitles={fixedTitles}
                publication={publication}
              />
            </div>
          ))}
        </div>

        {data?.page_items?.last_page > 1 ? (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <div className="d-flex align-items-center">
              {localStorage.getItem("language") === "en" ? (
                <div
                  className={`pub-arrow ${page === 1 ? "disable" : ""}`}
                  onClick={() => getPrevPage()}
                >
                  <svg
                    className="hide-hover"
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.9415 9.48999H0.461529" stroke="#806B5E" />
                    <path
                      d="M0.4527 9.50003L6.6427 0.780029"
                      stroke="#806B5E"
                    />
                    <path d="M6.6427 18.22L0.4527 9.5" stroke="#806B5E" />
                  </svg>
                  <svg
                    className="show-hover"
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.9415 9.48999H0.461529" stroke="#fff" />
                    <path d="M0.4527 9.50003L6.6427 0.780029" stroke="#fff" />
                    <path d="M6.6427 18.22L0.4527 9.5" stroke="#fff" />
                  </svg>
                </div>
              ) : (
                <div
                  className={`pub-arrow ${page === 1 ? "disable" : ""}`}
                  onClick={() => getPrevPage()}
                >
                  <svg
                    className="hide-hover"
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.05847 10.49H23.5385" stroke="#806B5E" />
                    <path d="M23.5473 10.5L17.3573 1.78003" stroke="#806B5E" />
                    <path d="M17.3573 19.22L23.5473 10.5" stroke="#806B5E" />
                  </svg>
                  <svg
                    className="show-hover"
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.05847 10.49H23.5385" stroke="#fff" />
                    <path d="M23.5473 10.5L17.3573 1.78003" stroke="#fff" />
                    <path d="M17.3573 19.22L23.5473 10.5" stroke="#fff" />
                  </svg>
                </div>
              )}

              {Array.from(
                { length: data?.page_items?.last_page },
                (_, index) => (
                  <div
                    key={index + 1}
                    className={`pub-page ${page === index + 1 ? "active" : ""}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    <p>{index + 1}</p>
                  </div>
                )
              )}
              {localStorage.getItem("language") === "en" ? (
                <div
                  className={`pub-arrow ${
                    page === data?.page_items?.last_page ? "disable" : ""
                  }`}
                  onClick={() => getNextPage()}
                >
                  <svg
                    className="hide-hover"
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.05847 10.49H23.5385" stroke="#806B5E" />
                    <path d="M23.5473 10.5L17.3573 1.78003" stroke="#806B5E" />
                    <path d="M17.3573 19.22L23.5473 10.5" stroke="#806B5E" />
                  </svg>
                  <svg
                    className="show-hover"
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.05847 10.49H23.5385" stroke="#fff" />
                    <path d="M23.5473 10.5L17.3573 1.78003" stroke="#fff" />
                    <path d="M17.3573 19.22L23.5473 10.5" stroke="#fff" />
                  </svg>
                </div>
              ) : (
                <div
                  className={`pub-arrow ${
                    page === data?.page_items?.last_page ? "disable" : ""
                  }`}
                  onClick={() => getNextPage()}
                >
                  <svg
                    className="hide-hover"
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.9415 9.48999H0.461529" stroke="#806B5E" />
                    <path
                      d="M0.4527 9.50003L6.6427 0.780029"
                      stroke="#806B5E"
                    />
                    <path d="M6.6427 18.22L0.4527 9.5" stroke="#806B5E" />
                  </svg>
                  <svg
                    className="show-hover"
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.9415 9.48999H0.461529" stroke="#fff" />
                    <path d="M0.4527 9.50003L6.6427 0.780029" stroke="#fff" />
                    <path d="M6.6427 18.22L0.4527 9.5" stroke="#fff" />
                  </svg>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  ) : (
    <></>
  );
}
