import { useContext, useEffect } from "react";
import Layout from "../components/Layout";
import Values from "../components/Values";
import GlobalState from "../GlobalState";
import { Helmet } from "react-helmet";

export default function About() {

    const { fixedTitles, global } = useContext(GlobalState);

    useEffect(() => {
        if (typeof window != "undefined") {
            setTimeout(() => {
                window?.triggerScroll();
            }, 300)
        }
    }, [window]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout activePage="about"  >
            <Helmet>
                <title>{global?.seo_pages['about'].title}</title>
                <meta name="title" content={global?.seo_pages['about'].title} />
                <meta name="description" content={global?.seo_pages['about'].description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={global?.seo_pages['about'].title} />
                <meta property="og:description" content={global?.seo_pages['about'].description} />
                <meta property="og:image" content={global?.seo_pages['about'].image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={global?.seo_pages['about'].title} />
                <meta property="twitter:description" content={global?.seo_pages['about'].description} />
                <meta property="twitter:image" content={global?.seo_pages['about'].image} />
            </Helmet>
            {fixedTitles ?
                <>
                    <div className="py-5"></div>
                    {global ?
                        <>

                            <div className="container-fluid py-5">
                                <div className="row align-items-center mx-lg-3 mx-md-2">
                                    <div className="text-center pb-5">
                                        <h2 className="mb-0" animate="up">{fixedTitles.about_us}</h2>
                                    </div>
                                    <div className="col-lg-6" animate="left">
                                        <div>
                                            <div className="mb-lg-0 mb-5 justify-text" dangerouslySetInnerHTML={{ __html: global.about_us.text }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6" animate="right">
                                        <div className="position-relative">
                                            <div className="ratio about-ratio">
                                                <img src={global.about_us.image} alt="about" />
                                            </div>
                                            <div className="opacity"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <hr className="seperator m-0" />
                            {
                                global.team_members.length ?

                                    <div className="container py-5">
                                        <div className="row mx-lg-3 mx-md-2" animate="up">
                                            <div className="col-12">
                                                <div className="text-center pb-5">
                                                    <h2 className="mb-0" animate="up">{fixedTitles.our_team}</h2>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            global.team_members.map((value, index) =>
                                                <>
                                                    <div className={'row pb-md-0 pb-5 team-wrapper ' + (index % 2 !== 0 ? ' justify-content-end' : ' ')} animate="up">
                                                        {index % 2 === 0 ?
                                                            <>
                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="ratio team-image">
                                                                        <img className="w-100 h-100" src={value.image} alt={value.full_name}></img>
                                                                    </div>

                                                                </div>

                                                                <div className="col-lg-1 col-md-1 col-sm-12">

                                                                </div>

                                                                <div className="col-lg-5 col-md-5 col-sm-12">
                                                                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                                                                        <div className="team-member-title">
                                                                            <h2>
                                                                                {value.full_name}
                                                                            </h2>
                                                                        </div>

                                                                        {value.position ?
                                                                            <div className="team-position">
                                                                                {value.position}
                                                                            </div>
                                                                            :
                                                                            <div className="mb-3"></div>
                                                                        }
                                                                        {console.log(global.team_members)}
                                                                        {
                                                                            value.description ?
                                                                                <div className="team-description">
                                                                                    {value.description}
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>

                                                                <div className="col-lg-4 col-md-6 col-sm-12 d-md-none d-block">
                                                                    <div className="ratio team-image">
                                                                        <img className="w-100 h-100" src={value.image} alt={value.full_name}></img>
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-5 col-md-5 col-sm-12">
                                                                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                                                                        <div className="team-member-title">
                                                                            <h2>
                                                                                {value.full_name}
                                                                            </h2>
                                                                        </div>
                                                                        <div className="team-position">
                                                                            {value.position}
                                                                        </div>
                                                                        <div className="team-description">
                                                                            {value.description}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-1 col-md-1 col-sm-12">

                                                                </div>

                                                                <div className="col-lg-4 col-md-6 col-sm-12 d-none d-md-block">
                                                                    <div className="ratio team-image">
                                                                        <img className="w-100 h-100" src={value.image} alt={value.full_name}></img>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className={'wrapper-between mt-md-4 ' + (index % 2 !== 0 ? ' rotated' : '') + (index + 1 === global.team_members.length ? ' d-none' : '')} >
                                                        <div className="ratio custom-ratio">
                                                            <img className="w-100 h-100" src="/assets/images/line.png" alt="" />
                                                        </div>
                                                    </div>
                                                </>

                                            )

                                        }



                                    </div>
                                    :
                                    null
                            }

                            <hr className="seperator m-0" />

                            <div className="container-fluid py-5">
                                <div className="row mx-lg-3 mx-md-2" animate="up">
                                    <div className="text-center pb-5">
                                        <h2 className="mb-0" animate="up">{fixedTitles.our_values}</h2>
                                    </div>
                                    {
                                        global.about_values.map((value, index) =>
                                            <div className="col-lg-4 col-md-6 col-sm-6 pb-4" key={index}>
                                                <Values
                                                    image={value.image}
                                                    title={value.title}
                                                    subtitle={value.text}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <hr className="seperator m-0" />

                            {/* Our Clients Section */}

                            <div className="container-fluid py-5">
                                <div className="row mx-lg-3 mx-md-2" animate="up">
                                    <div className="text-center pb-5">
                                        <h2 className="mb-0" animate="up">{fixedTitles.our_clients}</h2>
                                    </div>
                                </div>
                                <div className="row mx-lg-3 mx-md-2" animate="down">
                                    <div className="clients-grid">
                                        {
                                            global.clients.map((client, index) => (
                                                client?.link ?
                                                    <a href={client.link} target="_blank" rel="noreferrer">
                                                        <img className="clients-image" src={client?.full_path.image} alt={client.slug} />
                                                    </a>

                                                    :
                                                    <>
                                                        <img className="clients-image" src={client?.full_path.image} alt={client.slug} />
                                                    </>
                                            )
                                            )
                                        }
                                    </div>
                                </div>


                            </div>




                        </>
                        :
                        null
                    }
                </>
                :
                null
            }
        </Layout>
    );
}